import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Lottie } from "@crello/react-lottie"
import styled from "styled-components"

import animationData from "./../assets/404-group.json"
import Seo from "./../components/Seo"
import Button from "./../components/Button"
import Spacer from "./../components/Spacer"
import { breakpoints } from "../assets/globalStyles"

const Styled404Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #1c1c26;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 24px;
    @media screen and (min-width: ${breakpoints.lg}px) {
      font-size: 34px;
      line-height: 52px;
      margin-bottom: 16px;
    }
  }

  p {
    color: #626c79;
    font-family: Roboto;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    @media screen and (min-width: ${breakpoints.lg}px) {
      font-size: 24px;
      line-height: 36px;
    }
  }
`

export default function NotFound() {
  const {
    wp: {
      options: {
        optionsPageFields: { missingPageContent },
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          options {
            optionsPageFields {
              missingPageContent
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Seo />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Styled404Page>
              <Spacer size={40} when={{ md: 54 }} />
              <div
                dangerouslySetInnerHTML={{
                  __html: missingPageContent,
                }}
              />
              <Spacer size={32} when={{ md: 48 }} />
              <Button as={Link} to="/" colour="primary" variant="contained">
                Return to Home Page
              </Button>
              <Spacer size={24} when={{ md: 40 }} />
              <Lottie config={{ loop: true, autoplay: true, animationData }} />
            </Styled404Page>
          </div>
        </div>
      </div>
    </>
  )
}
